import React, {Component} from 'react';
import {Link} from 'gatsby';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import TopTen from '../images/pnw-top-10-hard-soft-skills.webp';
import Empathy from '../images/pnw-demonstrate-empathy@2x.webp';
import UnderThirty from '../images/pnw-under-thirty@2x.webp';
import Speak from '../images/pnw-speak@2x.webp';

import './pnw.scss';

class PNWForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                //$('#heroForm').hide();
                // $('.form-wrapper .thankyou').show();                
                let r = data[0];
                // let redirect_url = "https://mm-stats.com/9d8pf";

                formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                window.open(
                    'https://mm-stats.com/9d8pf',
                    '_blank' // <- This is what makes it open in a new tab.
                );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"name"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"company"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">Download the Infographic</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/merit-mile-2020-marketing-kickstart-top-10-hard-and-soft-skills/" />
                    <input type="hidden" name="auth" value="243fb7c0-b1e3-4f77-9d9a-12768db25962" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} pnw-wrapper`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                            <h1>What will help<br />the 2020 marketer<br />succeed?</h1>
                            <p>Read our infographic for the modern tools, skills, attitudes, and traits that 2020 marketers need to thrive. </p>
                        </div>
                        <div className="col-md-4 hero-bg">
                            <div className="inside-hero text-center">
                                <h2>Discover the top 10 Hard and Soft Skills Marketers Need to Succeed.</h2>
                                <div className="form-wrapper">
                                    <PNWForm />
                                    <div className="thankyou">
                                    <h4>Thank You</h4>
                                    <p>If the infographic does not immediately open please check your pop-up blocker. A copy will also be sent to your email.</p>
                                    <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="container intro">
                <div class="row">
                    <div class="col">
                        <p>2020 will no doubt be another year of rapid change and evolution – new platforms, new trends, new sentiments, new work cultures, and new ways of working will impact every business and every marketer behind it. </p>
                        <p>From adopting voice search and tackling artificial intelligence to embracing empathy leadership, what new and modern skills will you need to accomplish your goals? </p>
                    </div>
                </div>
            </div>

            <div class="container marketing">
                <div class="row">
                    <div class="col-lg-7">
                        <hr />
                        <p>Download our <strong>Top 10 Hard and Soft Skills 2020 Marketers Need to Succeed</strong> infographic and white paper to get your hands on the top digital marketing and interpersonal skills you need to master to achieve your desired results in 2020. </p>
                        <hr />
                    </div>
                    <div class="col-lg-5 text-center">
                        <img src={TopTen} alt="Top 10 Hard and Soft Skills" class="negmargin" />
                    </div>
                </div>
            </div>

            <div class="container impact">
                <div class="row">
                    <div class="col">
                        <h2>Learn about the impact of -</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 text-center">
                        <div class="card">
                            <p>Cultivating and applying empathy in marketing and leadership positions.</p>
                            <img src={Empathy} alt="96% of employees consider it important for leaders to demonstrate empathy" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                        <div class="card">
                            <p>Utilizing new social media platforms for niche communities.</p>
                            <img src={UnderThirty} alt="66% of TikTok's users are under 30" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                        
                        <div class="card">
                            <p>Understanding Google's new search algorithm for voice and mobile.</p>
                            <img src={Speak} alt="65% of users aged 25-49-year speak to their voice-enabled devices at least once a day." />
                        </div>
                    </div>
                </div>
            </div>

            <div class="container outro">
                <div class="row margin-b">
                    <div class="col">
                        <h3>What happens when you combine smart technologies, genuine kindness, and a healthy dose of ambition?</h3>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row margin-b">
                    <div className="col-12 col-md-6 text-center">
                        <p className="text-center">
                            <Link 
                                to="/marketing-skills/#top" 
                                scroll={el => this.scrollWithOffset(el, 70)} 
                                className="btn btn-primary btn-lg">
                                    DOWNLOAD THE INFOGRAPHIC
                            </Link>
                        </p>
                    </div>
                    <div className="col-12 col-md-6 text-center">
                        <p className="text-center">
                            <a href="https://www.meritmile.com/uploads/2020/03/the-10-hard-and-soft-skills-for-the-2020-marketer-white-paper.pdf" className="btn btn-primary btn-lg" target="_blank" rel="nofollow noopener noreferrer">
                                    DOWNLOAD THE WHITE PAPER
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </LayoutLPOne>
)